exports.components = {
  "component---src-pages-10th-customer-voice-tsx": () => import("./../../../src/pages/10th/customerVoice.tsx" /* webpackChunkName: "component---src-pages-10th-customer-voice-tsx" */),
  "component---src-pages-10th-index-tsx": () => import("./../../../src/pages/10th/index.tsx" /* webpackChunkName: "component---src-pages-10th-index-tsx" */),
  "component---src-pages-10th-infographics-tsx": () => import("./../../../src/pages/10th/infographics.tsx" /* webpackChunkName: "component---src-pages-10th-infographics-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-history-tsx": () => import("./../../../src/pages/about/history.tsx" /* webpackChunkName: "component---src-pages-about-history-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-method-btob-tsx": () => import("./../../../src/pages/method/btob.tsx" /* webpackChunkName: "component---src-pages-method-btob-tsx" */),
  "component---src-pages-method-btoc-tsx": () => import("./../../../src/pages/method/btoc.tsx" /* webpackChunkName: "component---src-pages-method-btoc-tsx" */),
  "component---src-pages-method-english-tsx": () => import("./../../../src/pages/method/english.tsx" /* webpackChunkName: "component---src-pages-method-english-tsx" */),
  "component---src-pages-method-global-tsx": () => import("./../../../src/pages/method/global.tsx" /* webpackChunkName: "component---src-pages-method-global-tsx" */),
  "component---src-pages-method-index-tsx": () => import("./../../../src/pages/method/index.tsx" /* webpackChunkName: "component---src-pages-method-index-tsx" */),
  "component---src-pages-presskit-index-tsx": () => import("./../../../src/pages/presskit/index.tsx" /* webpackChunkName: "component---src-pages-presskit-index-tsx" */),
  "component---src-pages-presskit-service-screenshots-tsx": () => import("./../../../src/pages/presskit/service-screenshots.tsx" /* webpackChunkName: "component---src-pages-presskit-service-screenshots-tsx" */),
  "component---src-templates-career-index-tsx": () => import("./../../../src/templates/career-index.tsx" /* webpackChunkName: "component---src-templates-career-index-tsx" */),
  "component---src-templates-career-position-tsx": () => import("./../../../src/templates/career-position.tsx" /* webpackChunkName: "component---src-templates-career-position-tsx" */),
  "component---src-templates-casestudy-article-tsx": () => import("./../../../src/templates/casestudy-article.tsx" /* webpackChunkName: "component---src-templates-casestudy-article-tsx" */),
  "component---src-templates-casestudy-index-tsx": () => import("./../../../src/templates/casestudy-index.tsx" /* webpackChunkName: "component---src-templates-casestudy-index-tsx" */),
  "component---src-templates-interview-article-tsx": () => import("./../../../src/templates/interview-article.tsx" /* webpackChunkName: "component---src-templates-interview-article-tsx" */),
  "component---src-templates-interview-index-tsx": () => import("./../../../src/templates/interview-index.tsx" /* webpackChunkName: "component---src-templates-interview-index-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/news-article.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news-index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-teacher-article-tsx": () => import("./../../../src/templates/teacher-article.tsx" /* webpackChunkName: "component---src-templates-teacher-article-tsx" */),
  "component---src-templates-teacher-index-tsx": () => import("./../../../src/templates/teacher-index.tsx" /* webpackChunkName: "component---src-templates-teacher-index-tsx" */)
}

